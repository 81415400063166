import React, { Fragment, useEffect, useRef } from 'react'
import { useLocation, useParams, useNavigate } from 'react-router'
import ErrorPage from '../ErrorPage/ErrorPage'
import { FaHome, FaInfo } from 'react-icons/fa'
import { useSelector, useDispatch } from 'react-redux'
import { Helmet } from 'react-helmet'
import { useGetGameMutation } from '../../../store/game/actions'
import {
  useGetAgoraTokenMutation,
  useGetAgoraUsersMutation,
  useGetJoinRoomInfoMutation,
  useGetParticipantsInfoMutation,
  useGetRoomInfoMutation,
  useLeaveRoomMutation,
  useStartRoomInfoMutation,
} from '../../../store/communication/action'
import classNames from 'classnames'
import './Game.css'
import { sendAnalytics } from '../../../utils/mixpannelConn'
import { setGame } from '../../../store/game/gameSlice'
import {
  Mic,
  MicOff,
  Error,
  CloseIcon,
  ExpanCircleIcon,
  MicBlocked,
  GameShare,
  Whatsapp,
} from '../../component/icones'
import { v4 as uuidv4 } from 'uuid'

import {
  Tooltip,
  CardImg,
  Card,
  CardBody,
  CardSubtitle,
  CardText,
} from 'reactstrap'
import { useState } from 'react'
import { toast } from 'react-hot-toast'
import { CreateOrJoinRoom } from '../../component/Multiplayer/CreateOrJoinRoom'
import { CreateRoom } from '../../component/Multiplayer/CreateRoom'
import { JoinRoom } from '../../component/Multiplayer/JoinRoom'
import { LobbyRoom } from '../../component/Multiplayer/LobbyRoom'
import { PreCreateRoom } from '../../component/Multiplayer/PreCreateRoom'
import { PreJoinRoom } from '../../component/Multiplayer/PreJoinRoom'
import { EnterNameModal } from '../../component/Multiplayer/EnterNameModal'
import { ChangeAvatarModal } from '../../component/Multiplayer/ChangeAvatarModal'
import { useLocalStorage } from '../../../hooks/useLocalStorage'
import { AreYouSureModal } from '../../component/Multiplayer/AreYouSureModal'
import Draggable from 'react-draggable'
import { DragIndicatorIcon } from '../../component/icones'
import { MicFun } from '../../component/Multiplayer/MicFun'
import { GameModeModal } from '../../component/Multiplayer/GameModeModal'
import { useUnload } from '../../../hooks/useUnload'
import { useMultiPlayerId } from '../../../hooks/useMultiPlayerId'
import { JoinRoomWithCode } from '../../component/Multiplayer/JoinRoomWithCode'
import { useMediaQuery } from '../../../hooks/useMediaQuery'
import {
  ROOM_DEACTIVATED,
  GAME_STARTED,
} from '../../../utils/muliplayerMessages'
import { getLocalStream } from '../../../utils/getLocalStream'
import { Player } from '@lottiefiles/react-lottie-player'
import { usePopState } from '../../../hooks/usePopstate'

const Game = () => {
  const dispatch = useDispatch()

  const [getGame, { isLoading: isGameLoading, isError, isSuccess }] =
    useGetGameMutation()
  const loginState = useSelector((state) => state.login)

  const [getJoinRoomInfo] = useGetJoinRoomInfoMutation()

  const [getRoomInfo] = useGetRoomInfoMutation()

  const [roomCode, setRoomCode] = useState('')

  const [counter, setCounter] = useState(6)

  const [showLoader, setShowLoader] = useState(true)

  const game = useSelector((state) => state.game)

  const location = useLocation()

  const { slug } = useParams()

  const [tooltipOpen, setTooltipOpen] = useState(false)

  const getUserNameFromStorage = () => {
    const userNameFromStorage = JSON.parse(localStorage.getItem('userName'))
    return userNameFromStorage ?? ''
  }

  const getUserAvatarFromStorage = () => {
    const userAvatar = JSON.parse(localStorage.getItem('avatar'))
    return userAvatar ?? ''
  }
  const [getAgoraToken, { isLoading: isTokenLoading }] =
    useGetAgoraTokenMutation()

  const cName = location.search.split('=')[1]
  const [channelName, setChannelName] = useState(cName)
  const uid = JSON.parse(localStorage.getItem('mobileNumber'))

  const [username, setUserName] = useState(getUserNameFromStorage())

  const [usedAvatar, setUsedAvatar] = useState(getUserAvatarFromStorage())

  const [userNameFromAgora, setAgoraUserName] = useLocalStorage(
    'agoraUserName',
    ''
  )

  const [selectedAvatar, setSelectedAvatar] = useLocalStorage(
    'selectedAvatar',
    ''
  )

  const [areAllAvatarsVisible, setAllAvatarsVisible] = useState(false)

  const [createRoomDetails, setCreateRoomDetails] = useState({
    current_count: '',
    invite_link: '',
    max_capacity: '',
    message: '',
    room_id: '',
    shareable_text: '',
  })

  const [getAgoraUsers, { isLoading: isAvatarsLoading }] =
    useGetAgoraUsersMutation()

  const [getParticipantsInfo, { isLoading: isPartcipantsLoading }] =
    useGetParticipantsInfoMutation()

  const [modalOpen, setModalOpen] = useState(false)

  const [isCollapseOpen, setIsCollapseOpen] = useState(false)

  const [isCreateRoomJoinRoomPopupOpen, setCreateRoomJoinRoomPopUpOpen] =
    useState(true)

  const [isAudioChatEnabled, setIsAudioChatEnabled] = useState(false)

  const [roomIsFull, setRoomIsFull] = useState(false)

  const [host, setHost] = useState({})

  const [avatars, setAvatars] = useState()

  const inputRef = useRef()

  const navigate = useNavigate()

  const toggle = () => setTooltipOpen(!tooltipOpen)

  const [roomKey, room_id] = location?.search?.split('=')

  const [joinRoomDetails, setJoinRoomDetails] = useState({})

  const [inviteLink, setInviteLink] = useState('')

  const [maxCapacity, setMaxCapacity] = useState('')

  const [gameGameSuccessfullyStarted, setGameSuccessfullyStarted] =
    useState(false)

  const [gameIframe, setGameIframe] = useState('')

  const getGamee = async (slug) => {
    try {
      const res = await getGame(slug).unwrap()
      return res.data
    } catch (error) {
      console.error(error)
    }
  }

  useEffect(() => {
    // Show the loader for 6 seconds
    const loaderTimeout = setTimeout(() => {
      setShowLoader(false)
    }, 2000) // 6 seconds delay in milliseconds

    return () => clearTimeout(loaderTimeout)
  }, [])

  const api_url = 'https://dev.sg.asgard.apis.simpleviralgames.com'

  const encodeData = (data) => {
    return Object.keys(data)
      .map(function (key) {
        return [key, data[key]].map(encodeURIComponent).join('=')
      })
      .join('&')
  }

  useEffect(() => {
    try {
      getGamee(slug).then((data) => {
        if (data) {
          const queryParamData = {
            game_id: data?.uuid,
            auth_type: 'basic',
            score_sync: data?.score_sync,
            state_sync: data?.state_sync,
            user_id: location?.search?.split('=')[1] ?? '',
            client_id: 'cc6b6644-7057-430c-acb8-1fb81cf7bcbb',
            tournament_mode: false,
            show_ads: false,
          }
          const queryParam = encodeData(queryParamData)
          setGameIframe(`${data?.url}?api_url=${api_url}&${queryParam}`)
          setIsAudioChatEnabled(data.multiplayer_config.audio_chat_enabled)
          dispatch(setGame(data))
          if (!location.state) {
            const properties = {
              GAME_NAME: data?.name,
              GAME_FROM: 'Game',
              GAME_UUID: data?.uuid,
              SEGMENT_NAME: 'Direct Landing',
            }
            sendAnalytics('track', properties, 'Play Game', ['mixpanel'])
          }
        }
      })
    } catch (error) {
      console.error(error)
    }
  }, [])

  useEffect(() => {
    if (
      location?.search?.split('=')[0]?.substring(1) === 'userId' &&
      location?.search?.split('=')[1]
    ) {
      const id = location?.search?.split('=')[1]
      sendAnalytics('identify', {}, id, ['mixpanel'])
    }
  }, [location])

  return (
    <div className="mx-auto">
      {showLoader && (
        <Player
          src="/animation_llkrpnwl.json"
          className="player fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"
          loop
          autoplay
          style={{ height: '200px', width: '200px' }}
        />
      )}
      {!showLoader && (
        <div className="p-0 max-w-full">
          <Helmet>
            <title>{`${game?.data?.property?.name} - Circles.Life`}</title>
            <meta
              name="description"
              content={`${game?.data?.property?.subtitle}`}
            />
          </Helmet>

          <Draggable>
            <div className="fixed top-1/4 right-0 z-10 flex items-center gap-2 bg-black  rounded-md">
              <div className="bg-black pl-3 w-full h-full">
                <DragIndicatorIcon />
              </div>
              <div
                className=" bg-[#EA5402] text-white flex w-fit items-center p-2 cursor-pointer"
                onClick={() => {
                  window.history.back()
                }}
                onTouchStart={() => {
                  window.history.back()
                }}
              >
                <CloseIcon height="1.5rem" width="1.5rem" />
                {/* <FaHome color="white" size={20} /> */}
              </div>
            </div>
          </Draggable>
          {/*/***********Home btn and info ***********************************/}
          {!game?.data?.multiplayer && (
            <iframe
              title={slug}
              className="game-frame no-scrollbar absolute top-0 h-full sm:h-full sm:top-0 left-0 bottom-0 right-0 w-full ml-auto mr-auto overflow-hidden"
              src={gameIframe}
              key={gameIframe}
            />
          )}

          {game?.data?.multiplayer && gameGameSuccessfullyStarted && (
            <iframe
              title={slug}
              className="game-frame no-scrollbar absolute top-[10%]  h-[90%] sm:h-full sm:top-0 left-0 bottom-0 right-0 w-full ml-auto mr-auto overflow-hidden"
              src={gameIframe}
              key={gameIframe}
            />
          )}
        </div>
      )}
      {isError && <ErrorPage></ErrorPage>}
    </div>
  )
}

export default Game
